.text-linear-gradient {
    background-image: linear-gradient(135deg, #74699d, #9386c4, #b6a8ea, #deae46, #f3d27f, #f1dca3);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
}

.hover-text-linear-gradient {
    &:hover {
        // color: var(--#{$prefix}primary) !important;
        // background-image: linear-gradient(135deg, #1e2b55, #57658f, #686181, #c99b37, #ddbc65, #ead291);
        // -webkit-background-clip: text;
        // background-clip: text;
        // color: transparent;
        // -webkit-text-fill-color: transparent;
        @extend .text-linear-gradient;
    }
}

.active {
    // color: var(--#{$prefix}primary) !important;
    background: linear-gradient(135deg,  #1e2b55, #57658f, #686181, #c99b37,#ddbc65, #ead291);
}

// .hover-border-b-light-primary {
//     &:hover {
//         border-bottom: 2px solid $light-primary !important;
//     }
// }

.page {
    padding: 50px 0;

    @include lg {
        min-height: calc(100vh - 102px);
    }
}

// spinner
.loading-spinner {
    border: 3px solid #e3e3e3;
    /* 灰色的圓圈 */
    border-top: 3px solid #7A7A7A;
    /* 上方的藍色，可自行調整顏色 */
    border-radius: 50%;
    width: 18px;
    height: 18px;
    animation: spin 1s linear infinite;
    /* 使用spin動畫 */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
        /* 起始角度 */
    }

    100% {
        transform: rotate(360deg);
        /* 結束角度 */
    }
}

// h2 {
//     color: white;
//     background-color: rgb(252, 151, 62);
//     // margin: 40px 0 20px;
//     padding: 12px 16px;
// }

// h2 {
//     // background: transparent;
//     border-left: 6px solid var(--#{$prefix}primary);
//     color: var(--#{$prefix}primary);
//     padding: 16px 0 16px 16px;
//     background: #f6f6f6;
// }

// h3 {
//     border-bottom: 1px dotted black;
//     color: var(--#{$prefix}secondary);
//     padding: 0 0 8px 0;
//     // margin: 30px 0 15px;
// }

// 按紐
a,
button,
.wp-block-button {
    @extend .text-white;
    @extend .d-block;
    @extend .py-1;
    @extend .px-3;
    @extend .btn;
    @extend .w-fit-content;
    @extend .bg-secondary;
}

a:has(> img) {
    @extend .p-0;
    background-color: transparent !important;
}

p:has(> a > img) {
    @extend .d-flex;
    @extend .flex-wrap;
}

// 表格
table {
    width: 100% !important;
    border-collapse: collapse;
}

th,
tr,
td {
    border: 1px solid black;
    padding: 10px;
}

// 圖片
figcaption {
    @extend .text-center;
}

// .wp-block-gallery {
//     @extend .d-flex;
//     @extend .flex-wrap;
//     @extend .justify-content-between;
//     > figure {
//         @include lg {
//             width: 49%;
//         };
//     }
//     img {
//         width: 100%;
//         height: auto;
//     }
// }

img {
    max-width: 100%;
    height: auto;
}

.gallery {
    @extend .d-flex;
    @extend .flex-wrap;

    // @extend .justify-content-between;
    img {
        width: 100%;
        height: auto;
    }
}

// .gallery-item {
//     @include lg {
//         width: 49%;
//     };

//     img {
//         width: 100%;
//         height: auto;
//     }
// }

// .gallery-size-medium > figure {
//     @include xl {
//         width: 32%;
//         &:not(:nth-child(3n)) {
//             margin-right: 2%;
//         }
//     }
// }

// .gallery-size-large > figure {
//     @include xl {
//         width: 49%;
//         &:nth-child(odd) {
//             margin-right: 2%;
//         }    
//     }
// }

// .gallery-size-full > figure {
//     @include xl {
//         width: 100%;
//     }
// }

// p:has(> img) {
// display: flex;
// align-items: end;
// flex-wrap: wrap;
// > img {
//     @include xl {
//         max-width: 32%;
//         max-height: 100%;
//         &:not(:nth-child(3n)) {
//             margin-right: 2%;
//         }
//     }    
// }
// }

p:has(> img) {
    width: 100%;
    img {
        max-width: 100%;
        height: auto;
    }
}

figure {
    max-width: 100%;
    // img {
    //     width: 100%;
    // }
}


// 影片
.wp-block-embed__wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    /* 16:9 aspect ratio */
    height: 0;
}

.wp-block-embed__wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

iframe {
    max-width: 100%;
}

// blockquote
blockquote {
    padding: 16px 0 16px 16px;
    border-left: 6px solid black;
}