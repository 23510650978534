.page-banner-container {
    height: 300px;
    @include md {
        height: 350px;
    }
    @include lg {
        height: 400px;
    }
    @include xl35 {
        height: 500px;
    }
}