body {
    font-family: "Noto Sans TC";
}

ul, li, div, p, h1, h2, h3, h4, h5, h6, button {
    margin-bottom: 0;
    padding: 0; 
}

a {
    text-decoration: none;
}

main {
    min-height: 100vh;
}

ul {
    list-style-type: none;
}