.homepage-ads-section, .homepage-main-content-section {
    max-width: 1920px;
}

.homepage-card-text {
    @extend .card-text;
    -webkit-line-clamp: 3; /* 限制顯示的行數 */
}

.homepage-card-body {
    min-height: 8.5rem;
}

.carousel-inner .active {
    background: transparent;
}

.homepage-horizontal-ad {
    width: 100% !important;
}